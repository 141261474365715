<template lang="">
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        Kullanıcılar
      </div>
    </template>
  </Card>
</template>
<script>
export default {};
</script>
<style lang=""></style>
